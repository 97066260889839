import React from 'react';

function App() {
  const domain = window.location.href.replace("https://", "").replace("http://","").replace("www.","").replace("/","");
  return (
    <div className="app" style={{backgroundImage: `url(${require("./image.png")})`}}>

    </div>
  );
}

export default App;
